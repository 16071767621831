<template>
  <div class="sm:mx-6 mt-6 py-6 px-6 sm:px-16">
    <CollectionCreateModal v-if="isOpenCreateModal" @close="closeCreateModal" />

    <AssetTitle v-if="user.email !== ''" title="My Collections" />

    <div class="flex justify-end mb-8">
      <t-button
        :disabled="user.state !== 'KYC_PASS'"
        class="w-60"
        @click="openCreateModal"
      >
        Create collection
      </t-button>
    </div>
    <div class="flex flex-wrap justify-center">
      <CollectionListCard
        v-for="collection in collections"
        :key="collection.id"
        :collection="collection"
      />
    </div>

    <Loader v-if="isLoading" :classes="'w-24 h-24 mx-auto'" />

    <h4
      v-if="!isLoading && collections.length === 0"
      class="text-24 text-center pt-8"
    >
      You have no Collections yet.
    </h4>
  </div>
</template>

<script>
import * as api from '@/api';
import AssetTitle from '@/components/AssetTitle';
import CollectionCreateModal from '@/components/modals/CollectionCreateModal';
import CollectionListCard from '@/components/cards/CollectionListCard';
import Loader from '@/components/Loader';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    CollectionCreateModal,
    AssetTitle,
    CollectionListCard,
    Loader,
  },
  data() {
    return {
      collections: [],
      busy: false,
      lastPage: 0,
      hasMore: true,
      isLoading: false,
      isOpenCreateModal: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  async mounted() {
    this.getUser();
    this.fetchCollections();
  },
  methods: {
    ...mapActions({
      getUser: 'user/GET_USER_DATA',
    }),
    async fetchCollections() {
      this.isLoading = true;
      this.collections = await api.get('collections', {
        my_collections: true,
      });
      this.isLoading = false;
    },
    openCreateModal() {
      this.isOpenCreateModal = true;
    },
    closeCreateModal() {
      this.isOpenCreateModal = false;
    },
  },
};
</script>
