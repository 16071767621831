<template>
  <button
    class="group p-4 mx-3 mb-6 rounded-xl border border-transparent hover:bg-blue-50 hover:border-blue-300 collection-card"
    @click="redirectToDetails"
  >
    <div class="flex flex-col">
      <div class="text-center">
        <div
          class="block w-full aspect-w-10 aspect-h-10 rounded-xl bg-gray-100 overflow-hidden"
        >
          <div v-if="!collection.cover_medium" class="rounded-lg top-24">
            no NFTs yet
          </div>

          <Loader
            v-else-if="!isImgLoaded"
            :classes="
              'object-cover rounded-lg w-1/3 h-1/3 absolute top-1/3 left-1/3'
            "
          />

          <img
            :src="collection.cover_medium"
            width="320"
            height="320"
            class="object-cover pointer-events-none "
            :class="isImgLoaded ? 'visible' : 'invisible'"
            :alt="collection.name"
            @load="onLoad"
          />
        </div>
        <h3 class="py-2 mb-2 text-24 leading-8 font-semibold truncate">
          {{ collection.name }}
        </h3>

        <button
          class="w-1/2 py-2 rounded-lg bg-white border border-blue-300 text-gray-700 text-12 leading-4 font-medium group-hover:bg-blue-450 group-hover:border-transparent group-hover:text-gray-50"
        >
          Show Collection
        </button>
      </div>
    </div>
  </button>
</template>
<script>
import Loader from '@/components/Loader';
import redirect from '@/utils/redirect';

export default {
  name: 'CollectionListCard',
  components: {
    Loader,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isImgLoaded: false,
    };
  },

  methods: {
    redirectToDetails() {
      redirect({
        name: 'CollectionDetails',
        params: {
          collection: this.collection.name,
        },
      });
    },
    onLoad() {
      this.isImgLoaded = true;
    },
  },
};
</script>
<style scoped>
.collection-card {
  width: 16rem;
  min-width: 16rem;
}
</style>
