<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="flex items-center text-24">
          Create new collection
        </div>
      </div>
      <div class="w-full border-b border-blue-450"></div>
      <div
        class="px-4 pb-4 sm:p-6 sm:pb-4 text-center flex flex-col items-center"
      >
        <form class="text-18 px-4 pb-4 sm:p-6 sm:pb-4 space-y-4">
          <div class="text-center">
            <label for="name">
              Name
            </label>
            <t-input id="name" ref="name" v-model="name" />
          </div>
          <div class="text-center">
            <label for="description">
              Description
            </label>
            <t-textarea id="description" v-model="description" />
          </div>
          <div v-if="errorMessage" class="text-center">
            <p class="text-red-500">{{ errorMessage }}</p>
          </div>
          <div class="max-w-max mx-auto">
            <t-button
              :disabled="!name || !description || isLoading"
              @click.prevent="createCollection"
            >
              {{ submitLabel }}
            </t-button>
          </div>
        </form>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';
import redirect from '@/utils/redirect';

import ModalWrapper from '@/components/modals/ModalWrapper';

export default {
  name: 'CollectionCreateModal',
  components: {
    ModalWrapper,
  },
  data() {
    return {
      // form
      name: '',
      description: '',

      // helpers
      errorMessage: '',
      isLoading: false,
    };
  },
  computed: {
    submitLabel() {
      return this.isLoading ? 'Creating...' : 'Create';
    },
  },
  async mounted() {
    this.$refs.name.focus();
  },
  methods: {
    async createCollection() {
      this.isLoading = true;
      try {
        const { name } = await api.post('collections', {
          name: this.name,
          description: this.description,
        });
        this.redirectToDetails(name);
        this.onClose();
      } catch (e) {
        console.error(e);
        this.errorMessage = "Couldn't create collection. Try again later.";
      }
      this.isLoading = false;
    },

    onClose() {
      this.$emit('close');
    },

    redirectToDetails(name) {
      redirect({
        name: 'CollectionDetails',
        params: {
          collection: name,
        },
      });
    },
  },
};
</script>
